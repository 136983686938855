import { Link } from "gatsby"
import React from "react"
import { useContext } from "react"
import { Container, Nav, Navbar, Row, Col} from "react-bootstrap"
import "../styles/styles.css"
import { LanguageContext } from "./language"
import { T } from "../translation/translate"
import { StaticImage } from 'gatsby-plugin-image'


export const LayoutBootstrap = ({children}) => {
  const {lang, setLang} = useContext(LanguageContext);

return(
<>
    <Navbar bg="#6ba7cf" className="Header" sticky='top' expand="sm">
      <Container fluid>
        <Navbar.Brand to="/" as={Link}> 
          <img
            src={'/img/logo.png'} 
            style={{width: "auto", height:'3rem'}}
            alt='Intersection' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll"/>
        <Navbar.Collapse id="navbarScroll">
          {/* laptop screens */}
          <Container className="d-none d-sm-block" fluid>
            <Nav className="justify-content-start">
              <Nav.Link  as={Link} className="navLink" style={{padding:'1rem!important'}} to="/association">{ T(lang, {nl: 'Over ons', en: 'About us',})}</Nav.Link>
              <Nav.Link  as={Link} className="navLink" style={{padding:'1rem!important'}} to="/students">{ T(lang, {nl: 'Studenten', en: 'Students',})}</Nav.Link>
              <Nav.Link  as={Link} className="navLink" style={{padding:'1rem!important'}} to="/companies">{ T(lang, {nl: 'Bedrijven', en: 'Companies',})}</Nav.Link>
              <Nav.Link  as={Link} className="navLink" style={{marginRight:'auto'}} to="/contact">{ T(lang, {nl: 'Contact', en: 'Contact',})}</Nav.Link>
              <Nav.Link style={{backgroundColor: (lang == 'en' ? 'rgba(0,0,0,0.1)' : null)}} className={`navLink`} onClick={e => setLang('en')}>EN</Nav.Link>
              <Nav.Link style={{backgroundColor: (lang == 'nl' ? 'rgba(0,0,0,0.1)' : null)}} className={`navLink`} onClick={e => setLang('nl')}>NL</Nav.Link>             
            </Nav>
          </Container>

          {/* mobile */}
          <Container className="d-block d-sm-none">
            <Nav className="me-auto">
              <Nav.Link className="navLink" as={Link} to="/association">{ T(lang, {nl: 'Over ons', en: 'About us',})}</Nav.Link>
              <Nav.Link className="navLink" as={Link} to="/students">{ T(lang, {nl: 'Studenten', en: 'Students',})}</Nav.Link>
              <Nav.Link className="navLink" as={Link} to="/companies">{ T(lang, {nl: 'Bedrijven', en: 'Companies',})}</Nav.Link>
              <Nav.Link className="navLink" as={Link} to="/contact">{ T(lang, {nl: 'Contact', en: 'Contact',})}</Nav.Link>
              <Nav.Link 
                style={{backgroundColor: (lang == 'en' ? 'rgba(0,0,0,0.1)' : null)}} 
                className={`navLink`} onClick={e => setLang('en')}
              >
                EN
              </Nav.Link>
              <Nav.Link style={{backgroundColor: (lang == 'nl' ? 'rgba(0,0,0,0.1)' : null)}} className={`navLink`} onClick={e => setLang('nl')}>NL</Nav.Link>
            
            </Nav>
          </Container>

        </Navbar.Collapse>
      </Container>
    </Navbar>
    {/*CSS transitions for transparent Navbar*/}
    

      {children}


    <Container fluid style={{zIndex:'100'}} className="closer">
        <Row>
          <Col style={{display:'flex', justifyContent: 'center'}}>
            <a href="mailto:intersection@tudelft.nl">
              <img alt="intersection@tudelft.nl" src="/img/email.png" style={{height:'1.5rem', margin:'1rem'}}/>
            </a>
            <a href="https://www.linkedin.com/company/study-association-intersection/">
              <img alt="linkedin.com/company/study-association-intersection"
                   src="/img/linkedin.png" 
                   style={{height:'1.5rem', margin:'1rem'}}/>
              </a>
          </Col>
        </Row>
    </Container>

</>


)

}
